define('tm-common/components/dynamic-edit-field', ['exports', 'moment'], function (exports, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        currentMatter: Ember.inject.service(),
        session: Ember.inject.service(),
        // to show simple editor or complex
        simple: true,
        onModal: false,

        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        layoutName: Ember.computed('field', function () {
            var type = 'undefined';

            if (this.get('field.readonly')) {
                return 'components/dynamic-edit-field/readonly-field';
            }

            switch (this.get('field.type')) {
                case 'text':
                    type = 'text';
                    break;
                case 'longtext':
                    type = 'textarea';
                    break;
                case 'longtext+markdown':
                    type = 'markdown';
                    break;
                case 'enum':
                    type = 'select';
                    break;
                case 'radio':
                    type = 'radio';
                    break;
                case 'checkboxes':
                    type = 'checkboxes';
                    break;
                case 'date':
                    type = 'date';
                    break;
            }
            return 'components/dynamic-edit-field/' + type + '-field';
        }),

        /**
         * supplied subject like object
         */
        model: {},
        /**
         * supplied matterField object
         */
        field: {},

        /**
         * optional action to take place when model['value'] changes
         */
        change: '',

        /**
         * should the input be disabled. default to 'false' but accept a user provided value
         */
        disabled: false,

        /**
         * extract from matterField
         * for the select case
         *
         * array of values w/o keys
         */
        options: Ember.computed('field', function () {
            return this.get('field.options');
        }),
        // sort options from app-lists by weight
        sortedOptions: Ember.computed.sort('options', 'sortDefinition'),
        sortDefinition: ['weight', 'display'],
        optionDisplayField: 'display',
        /**
         * pull value from model based on camelized matterField.value
         */
        value: Ember.computed('model', 'field.name', function () {
            return this.get('model.' + this.get('field.name'));
        }),

        radioGroupValue: Ember.computed('value', function () {
            var value = this.get('value');
            if (!Ember.isEmpty(value)) {
                return value.toString();
            }
            return value;
        }),

        /**
         * Method to convert from/to string separated by commas to array
         */
        checkedValues: Ember.computed('value', 'options.@each.value', function () {
            var checked = [];
            var currentValue = this.get('value');
            if (currentValue && Ember.isPresent(currentValue) && Ember.typeOf(currentValue) === 'string') {
                checked = currentValue.split(',');
            }
            return checked;
        }),

        handleBind: function handleBind(value) {
            // update model property with new value
            this.set('model.' + this.get('field.name'), value);
            // optional event to be fired when data changes
            this.sendAction('change', this.get('model'), this.get('field'), value);
        },


        bindRadioValue: Ember.observer('radioGroupValue', function () {
            this.handleBind(this.get('radioGroupValue'));
        }),

        /**
         * create 2 way data binding affect
         *
         */
        bind: Ember.observer('value', function () {
            this.handleBind(this.get('value'));
        }),

        /**
         * This methods are used on select-field type
         */
        selectedValue: Ember.computed('value', 'options.@each.value', function () {
            return this.get('options').findBy('value', this.get('value'));
        }),

        keyDown: function keyDown(e) {
            if (this.get('field.value') === 'ssan') {
                var key = e.charCode || e.keyCode || 0;
                // allow - (minus), backspace, delete, numbers, keypad numbers, home, end, tab only.
                return key === 189 || key === 8 || key === 46 || key === 9 || key >= 35 && key <= 40 || key >= 48 && key <= 57 || key >= 96 && key <= 105;
            }
        },

        /**
         * pikaday was showing a day before of the date saved on the db
         */
        simpleDate: Ember.computed('model', 'field', function () {
            var localTimezone = (0, _moment.default)(new Date()).format('ZZ');
            var date = this.get('model.' + this.get('field.name'));
            return (0, _moment.default)(date + ' 00:00 ' + localTimezone, 'YYYY-MM-DD HH:mm ZZ').toDate();
        }),

        actions: {
            /**
             * action fired when a select item is changed
             */
            changeValue: function changeValue(newValue) {
                this.set('selectedValue', newValue);
                if (newValue) {
                    this.set('value', newValue.get('value'));
                }
            },


            /**
             * update the checked values on multiselect-checkboxes component
             */
            updateCheckedValues: function updateCheckedValues(values, value, operation) {
                // we need to remove not existing options first
                var currentOptions = this.get('sortedOptions').map(function (x) {
                    return x.get('value');
                });
                this.set('value', values.filter(function (v) {
                    return currentOptions.includes(v);
                }).join(','));
            },
            toggleEditor: function toggleEditor() {
                var _this = this;

                var value = this.get('value');
                this.toggleProperty('simple');
                Ember.run.later(function () {
                    _this.set('value', value);
                }, 1000);
            },
            openModal: function openModal() {
                this.set('onModal', true);
            }
        },

        toastUIEditorOptions: Ember.computed(function () {
            var _this2 = this;

            var ENV = this.get('ENV');
            return {
                // https://www.programmersought.com/article/47486662747/
                addImageBlobHook: function addImageBlobHook(blob, callback, source) {
                    // TODO matter id, upload url, token, download url
                    var formData = new FormData();
                    formData.append('file[]', blob);
                    formData.append('docType_0', 'MARKDOWN_UPLOAD');
                    formData.append('matters_id', _this2.get('currentMatter.id'));
                    formData.append('subject_id_0', _this2.get('model.id'));
                    formData.append('token', _this2.get('session.data.authenticated.token'));
                    var ajax = new XMLHttpRequest();
                    ajax.open('POST', ENV.APP.restDestination + '/' + ENV.APP.restNameSpace + '/documents', true);
                    ajax.send(formData);
                    ajax.onreadystatechange = function () {
                        if (ajax.readyState === 4) {
                            if (ajax.status >= 200 && ajax.status < 300 || ajax.status === 304) {
                                var obj = JSON.parse(ajax.responseText);
                                callback(ENV.APP.restDestination + '/' + ENV.APP.restNameSpace + '/images/' + obj.documents[0].sha1);
                            }
                        }
                    };
                }
            };
        })
    });
});