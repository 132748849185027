define('tm-common/components/x-upload-file', ['exports', 'ember-uploader/uploaders/uploader', 'tm-common/templates/components/x-upload-file', 'ember-component-inbound-actions/inbound-actions'], function (exports, _uploader, _xUploadFile, _inboundActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inboundActions.default, {
        session: Ember.inject.service(),

        /**
         * used to get configuration from the application
         *
         * @property
         */
        ENV: Ember.computed(function () {
            var applicationConfig = Ember.getOwner(this).resolveRegistration('config:environment');
            return applicationConfig;
        }),

        /**
         * set the right layout to be rendered with this component
         */
        layout: _xUploadFile.default,

        /**
         * Check if the cancel button should be present
         */
        showCancelButton: true,

        /**
         * File ready to upload
         *
         * @property
         * @type Array
         */
        filesToUpload: [],

        /**
         * fileId
         *
         * @property
         * @type integer
         */
        fileId: null,

        /**
         * check if the upload button should be shown
         *
         * @property
         * @type boolean
         */
        showUploadButton: Ember.computed('uploader', 'filesToUpload.[]', function () {
            return Ember.isPresent(this.get('uploader')) && this.get('filesToUpload.length') > 0;
        }),

        /**
         * object in charge of the upload details
         * communication with server, send of the form data, etc
         *
         * @property
         * @type Uploader
         */
        uploader: null,

        /**
         * progress bar size / 100%
         */
        progress: 0,

        /**
         * if uploading process is running
         */
        uploading: false,

        /**
         * computed for style the progressbar
         */
        progressStyle: Ember.computed('progress', function () {
            var style = 'width: ' + this.get('progress') + '%;';
            return Ember.String.htmlSafe(style);
        }),

        /**
         * Init the uploader component
         *
         * @method
         */
        initializeComponent: Ember.on('init', function () {
            var self = this;

            // Initialize the uploader component
            Ember.set(this, 'filesToUpload', []);

            var uploader = _uploader.default.create({
                url: this.get('uploadURL')
            });

            uploader.on('didUpload', function () {
                self.set('filesToUpload', []);
                self.set('progress', 0);
            });

            uploader.on('progress', function (e) {
                self.set('progress', e.percent);
            });

            this.set('uploader', uploader);
        }),

        /**
         * API upload url
         *
         * @property
         */
        uploadURL: Ember.computed('ENV', function () {
            var ENV = this.get('ENV');
            return ENV.APP.restDestination + '/' + ENV.APP.restNameSpace + '/' + this.get('endPoint');
        }),

        /**
         * action name used to notify the controller
         * about the component being ready to upload
         * and called just before upload
         *
         * @event
         */
        beforeUpload: '',

        /**
         * action name used to notify the controller
         * about the component finished the upload
         *
         * @event
         */
        afterUpload: '',

        /**
         * action name used to notify the controller
         * in case of error during upload
         *
         * @event
         */
        errorUpload: '',

        /**
         * action name used to notify the controller
         * in case of user hitting cancel button
         *
         * @event
         */
        cancelUpload: '',

        /**
         * @property
         *
         * endpoint to hit for upload documents, default: documents
         */
        endPoint: 'files',

        /** *************** ACTIONS of the Component ********************/
        actions: {
            cancelUpload: function cancelUpload() {
                this.sendAction('cancelUpload');
            },
            deleteFile: function deleteFile(file) {
                this.get('filesToUpload').removeObject(file);
            },
            upload: function upload() {
                var _this = this;

                var self = this;
                this.set('uploading', true);

                self.sendAction('beforeUpload', this.get('filesToUpload'));

                var extraParameters = { token: this.get('session.data.authenticated.token') };
                this.get('filesToUpload').forEach(function (file, index) {
                    extraParameters['name_' + index] = file.get('name');
                    if (Ember.isPresent(file.get('extra'))) {
                        for (var key in file.get('extra')) {
                            extraParameters[key + '_' + index] = file.get('extra.' + key);
                        }
                    }
                });

                this.get('uploader').upload(this.get('filesToUpload').map(function (file) {
                    return file.file;
                }), extraParameters).then(function (response) {
                    if ('errors' in response && response.errors.length > 0) {
                        self.sendAction('errorUpload', response);
                    } else {
                        self.sendAction('afterUpload', response);
                    }
                }, function (error) {
                    self.sendAction('errorUpload', error);
                }).finally(function () {
                    _this.set('uploading', false);
                });
            }
        }
    });
});