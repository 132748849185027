define('tm-common/helpers/markdown', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.markdown = markdown;

    /*global SimpleMDE*/

    // Helper adapted from ember-simplemde/addon/helpers/simple-mde-preview.js

    /**
     * Handles blocks such as these:
     *
     *     - red
     *     - green
     *     - blue
     *
     *     - another list that is separated...
     *     - ...by some space from the previous one
     */
    function fixListBlocks(text) {
        var iteration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;

        if (iteration > 10) {
            return text;
        }

        var output = text.replaceAll(/(?<prefix>([*\-])\s+.*\n)\s*(?<suffix>\n([*\-])\s+)/g, function () {
            var matches = arguments[arguments.length - 1];
            return matches.prefix + '<br>' + matches.suffix;
        });

        if (text === output) {
            return output;
        }

        return fixListBlocks(output, iteration + 1);
    }

    function markdown(params /*, hash*/) {
        (false && !(params.length === 1) && Ember.assert('This helper requires one string parameter', params.length === 1));

        var plainText = params[0];
        if (Ember.isEmpty(plainText)) {
            return '';
        }
        (false && !(Ember.typeOf(plainText) === 'string') && Ember.assert('Parameter should be string', Ember.typeOf(plainText) === 'string'));


        plainText = fixListBlocks(plainText);

        var rendered = SimpleMDE.prototype.markdown(plainText);
        return Ember.String.htmlSafe(rendered);
    }

    exports.default = Ember.Helper.helper(markdown);
});