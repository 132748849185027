define('tm-common/models/receipt', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo;
    exports.default = Model.extend({
        name: attr('string'),
        size: attr('number'),
        viewable: attr('string'),

        // embedded key
        resourcesId: attr('number'),
        // keep split so I can change it via select
        // billableId: attr('number'),

        // relationship
        billableGroup: belongsTo('billable-group', { async: true }),
        billable: belongsTo('billable', { async: true }),

        available: Ember.computed(function () {
            var local = this.get('billable').get('id');
            // var local = this.get('billableId');
            if (local) {
                return false;
            } else {
                return true;
            }
        })
    });
});