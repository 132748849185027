define('tm-common/components/subject-avatar', ['exports', 'tm-common/templates/components/subject-avatar', 'tm-common/utils/get-app-url'], function (exports, _subjectAvatar, _getAppUrl) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _slicedToArray = function () {
        function sliceIterator(arr, i) {
            var _arr = [];
            var _n = true;
            var _d = false;
            var _e = undefined;

            try {
                for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
                    _arr.push(_s.value);

                    if (i && _arr.length === i) break;
                }
            } catch (err) {
                _d = true;
                _e = err;
            } finally {
                try {
                    if (!_n && _i["return"]) _i["return"]();
                } finally {
                    if (_d) throw _e;
                }
            }

            return _arr;
        }

        return function (arr, i) {
            if (Array.isArray(arr)) {
                return arr;
            } else if (Symbol.iterator in Object(arr)) {
                return sliceIterator(arr, i);
            } else {
                throw new TypeError("Invalid attempt to destructure non-iterable instance");
            }
        };
    }();

    exports.default = Ember.Component.extend({
        store: Ember.inject.service(),
        ajax: Ember.inject.service(),
        notify: Ember.inject.service(),

        ENV: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration('config:environment');
        }),

        layout: _subjectAvatar.default,

        tagName: '',

        /**
         * @property
         *
         * model passed to the component to show the avatar
         */
        subject: null,

        /**
         * @function
         *
         * every time new attrs are send we calculate the documentId, querying the documents
         * table related to the subject and of type Profile/Photo
         */
        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.setProfileId();
        },
        setProfileId: function setProfileId() {
            var _this = this;

            var documents = this.get('subject.documents');

            // check if we already have loaded the documents on the subject
            if (Ember.isPresent(documents)) {
                var _documents$sortBy$fil = documents.sortBy('updatedOn').filter(function (doc) {
                    var docType = doc.get('docType');
                    return docType === 'PROFILE PHOTO' && doc.get('doNotUse') === 0;
                }),
                    _documents$sortBy$fil2 = _slicedToArray(_documents$sortBy$fil, 1),
                    photoDoc = _documents$sortBy$fil2[0];

                if (photoDoc) {
                    this.set('documentId', photoDoc.get('id'));
                    this.set('documentSha1', photoDoc.get('sha1'));
                } else {
                    this.set('documentId', undefined);
                    this.set('documentSha1', undefined);
                }
            } else {
                // only ask the store if docs haven't been loaded yet
                var store = this.get('store');
                store.query('document', {
                    'v_resource_has_resources:parent_id': this.get('subject.id'),
                    with: 'v_resource_has_resources',
                    doc_type: 'PROFILE PHOTO',
                    do_not_use: 0
                }).then(function (response) {
                    if (_this.isDestroyed) {
                        return;
                    }
                    if (Ember.isPresent(response.get('firstObject'))) {
                        _this.set('documentId', response.get('firstObject.id'));
                        _this.set('documentSha1', response.get('firstObject.sha1'));
                    } else {
                        _this.set('documentId', undefined);
                        _this.set('documentSha1', undefined);
                    }
                });
            }
        },


        docObserver: Ember.observer('subject.documents.@each.{docType,viewable,doNotUse}', function () {
            this.setProfileId();
        }),

        /**
         * @property
         *
         * where we set the document id corresponding to the photo
         */
        documentId: null,

        /**
         * @computed
         *
         * check if documentId exists
         */
        hasAvatar: Ember.computed.notEmpty('documentId'),

        /**
         * @computed
         *
         * check if subject is male
         *
         * @return boolean
         */
        isMale: Ember.computed('subject.gender', function () {
            var gender = this.get('subject.gender');
            return gender === 'Male' || gender === 'M';
        }),

        /**
         * @computed
         *
         * check if subject is female
         *
         * @return boolean
         */
        isFemale: Ember.computed('subject.gender', function () {
            var gender = this.get('subject.gender');
            return gender === 'Female' || gender === 'F';
        }),

        /**
         * @computed
         *
         * check if subject is not male or female
         *
         * @return boolean
         */
        isNeutral: Ember.computed('isFemale', 'isMale', function () {
            return !this.get('isFemale') && !this.get('isMale');
        }),

        /**
         * size of the avatar
         */
        width: 100,
        height: 100,

        /**
         * if we want the avatar rounded
         */
        circle: false,
        mini: false,

        imgClasses: Ember.computed('circle', 'mini', function () {
            var classesString = 'subject-profile-image';
            if (this.get('circle')) {
                classesString += ' subject-profile-image-circle';
            }
            if (this.get('mini')) {
                classesString += ' subject-profile-image-small';
            }
            return classesString;
        }),

        /**
         * @computed
         *
         * return the url needed to preview the avatar
         */
        avatarUrl: Ember.computed('documentSha1', function () {
            var ENV = this.get('ENV');
            return (0, _getAppUrl.default)(ENV, '/images/' + this.get('documentSha1'));
        })
    });
});