define('tm-common/models/task-list', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        name: attr('string'),
        notes: attr('string'),
        weight: attr('number'),

        // For UI only not persisted
        addingTask: attr('boolean'),
        isEditing: attr('boolean'),
        isCommonEditing: attr('boolean'),
        selectAll: attr('boolean'),
        expanded: attr('boolean'),

        listTemplate: belongsTo('list-template', { async: true }),
        matter: belongsTo('matter', { async: true }),
        tasks: hasMany('task', { async: false }),

        selectTasks: Ember.observer('selectAll', function () {
            Ember.A(this.get('tasks')).filterBy('viewable', true).setEach('isSelected', this.get('selectAll'));
        }),

        taskSort: ['order'],
        sortedTasks: Ember.computed.sort('tasks', 'taskSort')
    });
});