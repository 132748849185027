define('tm-common/models/subject', ['exports', 'ember-data', 'moment', 'ember-api-actions'], function (exports, _emberData, _moment, _emberApiActions) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
        return typeof obj;
    } : function (obj) {
        return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
    };

    var Model = _emberData.default.Model,
        attr = _emberData.default.attr,
        belongsTo = _emberData.default.belongsTo,
        hasMany = _emberData.default.hasMany;
    exports.default = Model.extend({
        memberId: attr('number'),
        draftMemberId: attr('number'),
        // tmNum: attr('number'), // Not used in matter_fields
        firstName: attr('string'),
        lastName: attr('string'),
        middleName: attr('string'),
        gender: attr('string'),
        genderNotes: attr('string'),
        dob: attr('string'),
        dod: attr('string'),
        relationship: attr('string'),
        relationshipNotes: attr('string'),
        // salt not used in UI
        ssan: attr('string'),
        ssanNotes: attr('string'),
        alias: attr('string'),
        age: attr('string'),
        ageNotes: attr('string'),
        race: attr('string'),
        raceNotes: attr('string'),
        nationalOrigin: attr('string'),
        nationalOriginNotes: attr('string'),
        employment: attr('string'),
        employmentNotes: attr('string'),
        general: attr('string'),
        custom1: attr('string'),
        custom2: attr('string'),
        custom3: attr('string'),
        custom4: attr('string'),
        custom5: attr('string'),
        custom6: attr('string'),
        custom7: attr('string'),
        custom8: attr('string'),
        custom9: attr('string'),
        custom10: attr('string'),
        custom11: attr('string'),
        custom12: attr('string'),
        custom13: attr('string'),
        custom14: attr('string'),
        custom15: attr('string'),
        civil: attr('string'),
        civilNotes: attr('string'),
        civilFormPublicNotes: attr('string'),
        civilFormInternalNotes: attr('string'),
        criminal: attr('string'),
        criminalHistory: attr('string'),
        criminalFormPublicNotes: attr('string'),
        criminalFormInternalNotes: attr('string'),
        counties: attr('string'),
        countyNotes: attr('string'),
        affiliation: attr('string'),
        affiliationHistory: attr('string'),
        affiliationNotes: attr('string'),
        contributions: attr('string'),
        misc: attr('string'),
        miscNotes: attr('string'),
        socialMedia: attr('string'),
        socialMediaNotes: attr('string'),
        birth: attr('string'),
        education: attr('string'),
        educationNotes: attr('string'),
        medical: attr('string'),
        medicalNotes: attr('string'),
        marital: attr('string'),
        maritalNotes: attr('string'),
        bankruptcy: attr('string'),
        bankruptcyNotes: attr('string'),
        residential: attr('string'),
        residentialNotes: attr('string'),
        death: attr('string'),
        deathInformation: attr('string'),
        scope: attr('string'),
        traffic: attr('string'),
        trafficNotes: attr('string'),
        family: attr('string'),
        familyNotes: attr('string'),
        results: attr('string'),
        supplementalResearch: attr('string'),
        flagStatus: attr('string'),
        flagNote: attr('string'),
        identity: attr('string'),
        highlights: attr('string'),
        subjectType: attr('string', { defaultValue: 'other_subjects' }),
        smNotes: attr('string'),
        monitor: attr('number'),
        monitorFrequency: attr('string'),
        notes: attr('string'),
        identityStatus: attr('string'),
        socnetNotifiedOn: attr('estdate'),
        socnetNotifiedBy: attr('number'),
        vehicle: attr('string'),
        expertWitnessHistory: attr('string'),
        expertWitnessHistoryNotes: attr('string'),
        published: attr('boolean'),
        pending: attr('boolean'),
        publishedOn: attr('estdate'),
        unpublishedOn: attr('estdate'),
        caseClassification: attr('string'),
        researchDisclaimer: attr('string'),

        location: attr('string'),
        locationNotes: attr('string'),
        locationOrder: attr('number'),
        foreperson: attr('number'),
        seatNumber: attr('number'),
        courtPanel: attr('string'),
        draftCourtPanel: attr('string'),
        courtJuror: attr('string'),
        injured: attr('string'),
        broughtSuit: attr('string'),
        deceased: attr('string'),
        forensicCapture: attr('string'),
        depDate: attr('string'),
        civilJudgmentsOrLiens: attr('string'),
        foreclosure: attr('string'),
        federalCivil: attr('string'),
        workersCompensation: attr('string'),
        captureStatus: attr('string'),
        publishedMessage: attr('string'),
        publishedOption: attr('string'),

        // control in the backend
        saveOrderPanel: attr('number', { defaultValue: 1 }),

        // resource fields
        childTable: attr('string', { defaultValue: 'subjects' }),
        syncId: attr('number'),
        createdOn: attr('estdate'),
        updatedOn: attr('estdate'),
        fullProfileUpdatedOn: attr('estdate'),
        viewable: attr('number'),

        createdBy: belongsTo('user', { async: true }),
        updatedBy: belongsTo('user', { async: true }),
        copiedFromId: attr('number'),
        fullProfileUpdatedBy: belongsTo('user', { async: true }),

        // calculated
        fullName: Ember.computed('firstName', 'lastName', function () {
            if (Ember.isEmpty(this.get('lastName')) && Ember.isEmpty(this.get('firstName'))) {
                return null;
            }
            if (Ember.isPresent(this.get('lastName')) && Ember.isEmpty(this.get('firstName'))) {
                return this.get('lastName');
            }
            if (Ember.isPresent(this.get('lastName')) && Ember.isPresent(this.get('firstName'))) {
                return this.get('lastName') + ', ' + this.get('firstName');
            }
            return this.get('lastName') + ', ' + this.get('firstName');
        }),

        firstLastName: Ember.computed('firstName', 'lastName', function () {
            if (!this.get('lastName') && !this.get('firstName')) {
                return null;
            }
            if (Ember.isEmpty(this.get('lastName')) && Ember.isEmpty(this.get('firstName'))) {
                return null;
            }
            if (Ember.isPresent(this.get('lastName')) && Ember.isEmpty(this.get('firstName'))) {
                return this.get('lastName');
            }
            if (Ember.isPresent(this.get('lastName')) && Ember.isPresent(this.get('firstName'))) {
                return this.get('firstName') + ' ' + this.get('lastName');
            }
            return this.get('firstName') + ' ' + this.get('lastName');
        }),

        displayLastName: Ember.computed('subjectType', function () {
            switch (this.get('subjectType')) {
                case 'other_subjects':
                    return 'Last Name / Entity Name';
                default:
                    return 'Last Name';
            }
        }),

        fullNameWithMiddle: Ember.computed('fullName', 'middleName', function () {
            var fullName = this.get('fullName');
            var middleName = this.get('middleName');
            if (middleName) {
                fullName = fullName + ' ' + this.get('middleName');
            }
            return fullName;
        }),

        hasNumbers: Ember.computed('numbers', function () {
            return !Ember.isEmpty(this.numbers);
        }),

        numbers: Ember.computed('memberId', 'courtPanel', 'courtJuror', function () {
            var _getProperties = this.getProperties('memberId', 'courtPanel', 'courtJuror'),
                memberId = _getProperties.memberId,
                courtPanel = _getProperties.courtPanel,
                courtJuror = _getProperties.courtJuror;

            return [memberId, courtPanel, courtJuror].filter(function (item) {
                return !Ember.isEmpty(item);
            });
        }),

        nameAndNumbers: Ember.computed('fullName', 'numbers', function () {
            var _getProperties2 = this.getProperties('fullName', 'numbers'),
                fullName = _getProperties2.fullName,
                numbers = _getProperties2.numbers;

            var numbersValue = numbers.join('/');

            return [numbersValue, fullName].join(' - ');
        }),

        sortBy: ['date'],
        sortedTimelineEvents: Ember.computed.sort('timelineEvents', 'sortBy'),
        hasEvents: function hasEvents() {
            return this.get('timelineEvents').length > 0;
        },


        // custom actions
        // /subjects/123/publish
        publish: (0, _emberApiActions.memberAction)({ path: 'publish', type: 'put' }),
        publishLimited: (0, _emberApiActions.memberAction)({ path: 'publish-limited', type: 'put' }),
        unpublish: (0, _emberApiActions.memberAction)({ path: 'unpublish', type: 'put' }),
        reset: (0, _emberApiActions.memberAction)({ path: 'reset', type: 'put' }),
        makeViewable: (0, _emberApiActions.memberAction)({ path: 'make_viewable', type: 'put' }),

        renumberSubjects: (0, _emberApiActions.memberAction)({ path: 'renumber_subjects', type: 'post' }),
        patch: (0, _emberApiActions.memberAction)({ path: 'patch', type: 'put' }),

        getNoteCommunications: (0, _emberApiActions.memberAction)({
            path: 'note_communications',
            type: 'get'
        }),

        saveFlag: (0, _emberApiActions.memberAction)({ path: 'flag', type: 'put' }),

        saveAnalytic: (0, _emberApiActions.memberAction)({ path: 'analytic', type: 'put' }),

        saveWorkflow: (0, _emberApiActions.memberAction)({ path: 'workflow', type: 'put' }),

        // relationships
        matter: belongsTo('matter', {
            async: false
        }),

        copiedFrom: belongsTo('subject', { async: true }),

        simpleWorkflows: hasMany('simple-workflow', { async: false }),

        subjectRevisions: hasMany('subject-revision', {
            async: false
        }),

        documents: hasMany('document', {
            async: false,
            inverse: 'subject'
        }),
        visibleDocuments: Ember.computed('documents', function () {
            return this.get('documents').rejectBy('docType', 'MARKDOWN_UPLOAD');
        }),
        // using the serializer to transform the note_ids to subject_note_ids
        subjectNotes: hasMany('note', {
            async: false
        }),
        hyperlinks: hasMany('hyperlink', {
            async: false
        }),
        dbMemos: hasMany('db-memo', {
            async: false
        }),
        timelineEvents: hasMany('timeline-event', {
            async: false
        }),
        addresses: hasMany('subject-addr', {
            async: false
        }),

        monitoringActivity: Ember.computed('subjectNotes.[]', function () {
            return this.get('subjectNotes').filterBy('viewable').filterBy('noteType', '1');
        }),
        monitoringNotes: Ember.computed('subjectNotes.[]', function () {
            return this.get('subjectNotes').rejectBy('viewable').filterBy('noteType', '1');
        }),
        normalNotes: Ember.computed('subjectNotes.[]', function () {
            return this.get('subjectNotes').filterBy('noteType', '0');
        }),
        numberOfDisplayedEvents: Ember.computed('timelineEvent.[]', function () {
            return this.get('timelineEvents').reduce(function (acc, te) {
                return acc + (Ember.isPresent(te.get('endDate')) ? 2 : 1);
            }, 0);
        }),

        monitoringSort: ['createdOn:desc'],
        sortedMonitoringActivity: Ember.computed.sort('monitoringActivity', 'monitoringSort'),
        sortedMonitoringNotes: Ember.computed.sort('monitoringNotes', 'monitoringSort'),

        latestMonitoringActivity: Ember.computed('sortedMonitoringActivity', function () {
            return this.get('sortedMonitoringActivity').slice(0, 2);
        }),
        latestMonitoringNotes: Ember.computed('sortedMonitoringNotes', function () {
            return this.get('sortedMonitoringNotes').slice(0, 2);
        }),

        isChecked: attr('boolean'),

        showIsResearchedBadge: Ember.computed('caseClassification', function () {
            var classification = this.get('caseClassification');
            if (Ember.isEmpty(classification)) {
                classification = '';
            }
            return classification.indexOf('Case Subject') >= 0;
        }),
        showIsFlagged: Ember.computed.notEmpty('flagNote'),

        publishedBy: belongsTo('user', { async: true }),
        unpublishedBy: belongsTo('user', { async: true }),

        switchSubject: (0, _emberApiActions.memberAction)({
            path: 'switch_subject',
            type: 'get',
            urlType: 'findRecord'
        }),

        recordView: (0, _emberApiActions.memberAction)({
            path: 'record_view',
            type: 'put'
        }),

        // analytics fields
        states: _emberData.default.attr('string'),
        statesOther: _emberData.default.attr('string'),
        property: _emberData.default.attr('string'),
        primaryPropertyValue: _emberData.default.attr('string'),
        civilPlaintiff: _emberData.default.attr('string'),
        civilDefendant: _emberData.default.attr('string'),
        civilBankruptcies: _emberData.default.attr('string'),
        civilUnknown: _emberData.default.attr('string'),
        civilWorkcomp: _emberData.default.attr('string'),
        civilAnalysis: _emberData.default.attr('string'),
        civilOutsideOfScope: _emberData.default.attr('number'),
        civilPartytype: _emberData.default.attr('string'),
        criminalTotal: _emberData.default.attr('string'),
        criminalAnalysis: _emberData.default.attr('string'),
        criminalCharge: _emberData.default.attr('string'),
        criminalRights: _emberData.default.attr('string'),
        criminalCases: _emberData.default.attr('string'),
        trafficAnalysis: _emberData.default.attr('string'),
        employmentAnalysis: _emberData.default.attr('string'),
        employmentAnalysisCategory: _emberData.default.attr('string'),
        employmentAnalysisLicense: _emberData.default.attr('string'),
        employmentAnalysisConnections: _emberData.default.attr('string'),
        employmentStatus: _emberData.default.attr('string'),
        employmentFactors: _emberData.default.attr('string'),
        employmentFactorsVeteran: _emberData.default.attr('string'),
        employmentPosition: _emberData.default.attr('string'),
        employmentPositionCurrent: _emberData.default.attr('string'),
        employmentLengthCurrent: _emberData.default.attr('string'),
        socnetActivity: _emberData.default.attr('string'),
        socnetThemes: _emberData.default.attr('string'),
        socnetThemesHidden: _emberData.default.attr('string'),
        socnetThemesOther: _emberData.default.attr('string'),
        socnetTones: _emberData.default.attr('string'),
        socnetTonesOther: _emberData.default.attr('string'),
        socnetConnections: _emberData.default.attr('string'),
        socnetOther: _emberData.default.attr('string'),
        socnetNote: _emberData.default.attr('string'),
        possibleConnectionsCategory: _emberData.default.attr('string'),
        educationAnalysis: _emberData.default.attr('string'),
        higherEducation: _emberData.default.attr('string'),
        higherEducationMasters: _emberData.default.attr('string'),
        higherEducationPostGrad: _emberData.default.attr('string'),
        religiousInterests: _emberData.default.attr('string'),
        religiousAffiliation: _emberData.default.attr('string'),
        religiousLevelOfInterest: _emberData.default.attr('string'),
        maritalStatus: _emberData.default.attr('string'),
        maritalAttributes: _emberData.default.attr('string'),
        maritalStability: _emberData.default.attr('string'),
        children: _emberData.default.attr('string'),
        hasChildren: _emberData.default.attr('string'),
        employmentType: _emberData.default.attr('string'),
        employmentTypeCurrent: _emberData.default.attr('string'),
        employmentRole: _emberData.default.attr('string'),
        employmentOther: _emberData.default.attr('string'),
        employmentCurrentLast: _emberData.default.attr('string'),
        jobLevel: _emberData.default.attr('string'),
        employmentStability: _emberData.default.attr('string'),
        height: _emberData.default.attr('string'),
        occupations: _emberData.default.attr('string'),
        productUse: _emberData.default.attr('string'),
        productUseSecondaries: _emberData.default.attr('string'),
        addictionWithdrawalExperience: _emberData.default.attr('string'),
        jurorMedicalIssues: _emberData.default.attr('string'),
        similarProductNotes: _emberData.default.attr('string'),
        residentialStability: _emberData.default.attr('string'),
        politicalViews: _emberData.default.attr('string'),
        mediaOutlet: _emberData.default.attr('string'),
        mediaOutletNotes: _emberData.default.attr('string'),
        priorJurorService: _emberData.default.attr('string'),
        verdictReached: _emberData.default.attr('string'),
        forepersonHistory: _emberData.default.attr('string'),
        marriageCount: _emberData.default.attr('string'),
        yearsMarried: _emberData.default.attr('string'),
        yearsEmployed: _emberData.default.attr('string'),
        jobSatisfaction: _emberData.default.attr('string'),
        yearsInState: _emberData.default.attr('string'),
        longTermResident: _emberData.default.attr('string'),
        yearsInCounty: _emberData.default.attr('string'),
        questionnaire: _emberData.default.attr('string'),
        governmentSector: _emberData.default.attr('string'),
        governmentSectorArea: _emberData.default.attr('string'),
        governmentSectorBranches: _emberData.default.attr('string'),
        governmentSectorCurrent: _emberData.default.attr('string'),
        currentCity: _emberData.default.attr('string'),
        militaryVeteran: _emberData.default.attr('string'),
        militaryVeteranBranches: _emberData.default.attr('string'),
        militaryVeteranRank: _emberData.default.attr('string'),
        militaryVeteranYearsOfService: _emberData.default.attr('string'),
        militaryVeteranCurrent: _emberData.default.attr('string'),

        isGovSector: Ember.computed('governmentSectorCurrent', function () {
            var governmentSector = this.get('governmentSectorCurrent');
            return governmentSector && governmentSector === '1';
        }),

        isMilitaryVet: Ember.computed('militaryVeteranCurrent', function () {
            var militaryVeteranCurrent = this.get('militaryVeteranCurrent');
            return militaryVeteranCurrent && militaryVeteranCurrent === '1';
        }),

        // analytics computed properties
        subjectAnalyticsStates: Ember.computed('states', {
            get: function get(key) {
                var states = this.get('states');
                if (states) {
                    return states.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedStates = value.filterBy('checked', true);
                if (!selectedStates.findBy('display', 'Other')) {
                    this.set('statesOther', null);
                }
                this.set('states', selectedStates.map(function (s) {
                    return s.get('display');
                }).join(', '));

                return selectedStates.map(function (item) {
                    return item.get('display');
                });
            }
        }),

        subjectAnalyticsStatesRead: Ember.computed('subjectAnalyticsStates', function () {
            return '\n            ' + this.get('subjectAnalyticsStates').map(function (item, i) {
                return ('\n              ' + item + '<br/>\n            ').trim();
            }).join('') + '\n          ';
        }),

        subjectAnalyticsCivil: Ember.computed('civilAnalysis', {
            get: function get(key) {
                if (!this.get('civilAnalysis')) {
                    return [];
                }

                if (this.get('civilAnalysis') === 'No Civil Records') {
                    return this.get('civilAnalysis');
                }

                var civilAnalysis = JSON.parse(this.get('civilAnalysis'));
                var civilData = [];
                civilAnalysis.forEach(function (item) {
                    civilData.push(Ember.Object.create({
                        order: item['order'],
                        year: item['year'],
                        case_type: item['case_type'],
                        case_type_analytics_key: item['case_type_analytics_key'],
                        case_type_analytics_label: item['case_type_analytics_label'],
                        party_type: item['party_type'],
                        party_type_analytics: item['party_type_analytics'],
                        case_notes: item['case_notes'],
                        qty: item['qty'] ? item['qty'] : 1,
                        is_markdown: item['is_markdown']
                    }));
                });

                return civilData;
            },
            set: function set(key, value) {
                if (!value || (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' && !value.length) {
                    this.set('civilAnalysis', null);
                    return [];
                }

                if (value === 'No Civil Records') {
                    this.set('civilAnalysis', value);
                    return value;
                }

                this.set('civilAnalysis', JSON.stringify(value));
                return value;
            }
        }),

        subjectAnalyticsCivilPartytype: Ember.computed('civilPartytype', {
            get: function get(key) {
                var civilPartytype = this.get('civilPartytype') ? JSON.parse(this.get('civilPartytype')) : false;

                if (civilPartytype) {
                    return Object.keys(civilPartytype).map(function (k) {
                        return Ember.Object.create({
                            display: k,
                            plaintiff: civilPartytype[k][0],
                            defendant: civilPartytype[k][1],
                            other: civilPartytype[k][2]
                        });
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedCivil = value.filterBy('checked', true);

                if (selectedCivil) {
                    var noneSelected = selectedCivil.findBy('shortName', 'no_civil_records');

                    if (noneSelected) {
                        this.set('civilPartytype', null);
                        return null;
                    } else {
                        var civilPartytypeObj = {};
                        selectedCivil.forEach(function (c) {
                            civilPartytypeObj[c.get('shortName')] = [c.get('plaintiffCount').toString(), c.get('defendantCount').toString(), c.get('otherCount').toString()];
                        });
                        this.set('civilPartytype', JSON.stringify(civilPartytypeObj));

                        return Object.keys(civilPartytypeObj).map(function (k) {
                            return Ember.Object.create({
                                display: k,
                                plaintiff: civilPartytypeObj[k][0],
                                defendant: civilPartytypeObj[k][1],
                                other: civilPartytypeObj[k][2]
                            });
                        });
                    }
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsCriminal: Ember.computed('criminalAnalysis', {
            get: function get(key) {
                var criminalAnalysis = this.get('criminalAnalysis');
                if (criminalAnalysis) {
                    return criminalAnalysis.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedCriminal = value.criminalAnalysis.filterBy('checked', true);
                var noneOption = value.criminalAnalysis.findBy('shortName', 'no_criminal');

                if (selectedCriminal) {
                    this.set('criminalAnalysis', selectedCriminal.map(function (c) {
                        return c.get('display');
                    }).join(', '));

                    if (noneOption.get('checked')) {
                        this.set('criminalTotal', '');
                    }

                    return selectedCriminal.map(function (item) {
                        return item.get('display');
                    });
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsCriminalCases: Ember.computed('criminalCases', {
            get: function get(key) {
                if (!this.get('criminalCases')) {
                    return [];
                }

                if (this.get('criminalCases') === 'No Criminal Records') {
                    return this.get('criminalCases');
                }

                var criminalAnalysis = JSON.parse(this.get('criminalCases'));
                var criminalData = [];
                criminalAnalysis.forEach(function (item) {
                    criminalData.push(Ember.Object.create({
                        order: item['order'],
                        year: item['year'],
                        case_type: item['case_type'],
                        case_type_analytics_key: item['case_type_analytics_key'],
                        case_type_analytics_label: item['case_type_analytics_label'],
                        case_notes: item['case_notes'],
                        qty: item['qty'] ? item['qty'] : 1,
                        is_markdown: item['is_markdown']
                    }));
                });

                return criminalData;
            },
            set: function set(key, value) {
                if (!value || (typeof value === 'undefined' ? 'undefined' : _typeof(value)) === 'object' && !value.length) {
                    this.set('criminalCases', null);
                    return [];
                }

                if (value === 'No Criminal Records') {
                    this.set('criminalCases', value);
                    return value;
                }

                this.set('criminalCases', JSON.stringify(value));
                return value;
            }
        }),

        subjectAnalyticsCriminalCharges: Ember.computed('criminalCharge', {
            get: function get(key) {
                var criminalCharge = this.get('criminalCharge');
                if (criminalCharge) {
                    return criminalCharge.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var noneOption = value.criminalAnalysis.findBy('shortName', 'no_criminal');

                if (noneOption.get('checked')) {
                    this.set('criminalCharge', null);
                    return null;
                } else {
                    var selectedCriminalCharges = value.criminalCharges.filterBy('checked', true);

                    if (selectedCriminalCharges) {
                        this.set('criminalCharge', selectedCriminalCharges.map(function (c) {
                            return c.get('display');
                        }).join(', '));

                        return selectedCriminalCharges.map(function (item) {
                            return item.get('display');
                        });
                    } else {
                        return false;
                    }
                }
            }
        }),

        subjectAnalyticsCriminalRights: Ember.computed('criminalRights', {
            get: function get(key) {
                var criminalRights = this.get('criminalRights');
                if (criminalRights) {
                    return criminalRights.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var noneOption = value.criminalAnalysis.findBy('shortName', 'no_criminal');
                var hasFelonyConvictions = value.criminalCharges.findBy('shortName', 'has_felony_convictions');

                if (noneOption.get('checked') || !hasFelonyConvictions.get('checked')) {
                    this.set('criminalRights', null);
                    return null;
                } else {
                    var selectedCriminalRights = this.get('criminalRights');

                    if (selectedCriminalRights) {
                        return [selectedCriminalRights];
                    } else {
                        return false;
                    }
                }
            }
        }),

        subjectAnalyticsCriminalRightsRead: Ember.computed('subjectCriminalRights', function () {
            return '\n            ' + this.get('subjectCriminalRights').map(function (item, i) {
                return ('\n              ' + item + '<br/>\n            ').trim();
            }).join('') + '\n          ';
        }),

        subjectAnalyticsTraffic: Ember.computed('trafficAnalysis', {
            get: function get(key) {
                var traffic = this.get('trafficAnalysis');
                if (traffic) {
                    return traffic.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedTraffic = value.filterBy('checked', true);

                if (selectedTraffic) {
                    this.set('trafficAnalysis', selectedTraffic.map(function (c) {
                        return c.get('display');
                    }).join(', '));

                    return selectedTraffic.map(function (item) {
                        return item.get('display');
                    });
                } else {
                    return null;
                }
            }
        }),

        subjectAnalyticsEmploymentStatus: Ember.computed('employmentStatus', {
            get: function get(key) {
                var employmentStatus = this.get('employmentStatus');
                if (employmentStatus) {
                    return employmentStatus.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedEmploymentStatus = value.employmentStatus.filterBy('checked', true).rejectBy('display', 'Unknown');
                var unknownOption = value.employmentStatus.findBy('shortName', 'employment_status_unknown');

                if (unknownOption.get('checked')) {
                    this.set('employmentStatus', 'Unknown');
                    return ['Unknown'];
                } else {
                    if (selectedEmploymentStatus) {
                        this.set('employmentStatus', selectedEmploymentStatus.map(function (e) {
                            return e.get('display');
                        }).join(', '));

                        return selectedEmploymentStatus.map(function (item) {
                            return item.get('display');
                        });
                    } else {
                        return false;
                    }
                }
            }
        }),

        subjectAnalyticsEmploymentAnalysisCategory: Ember.computed('employmentAnalysisCategory', {
            get: function get(key) {
                var employmentAnalysisCategory = this.get('employmentAnalysisCategory');
                if (employmentAnalysisCategory) {
                    return employmentAnalysisCategory.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var employmentAnalysisCategory = value.employmentAnalysisCategory;
                var selectedEmplymentAnalysisCategory = employmentAnalysisCategory.filterBy('checked', true);

                if (selectedEmplymentAnalysisCategory) {
                    this.set('employmentAnalysisCategory', selectedEmplymentAnalysisCategory.map(function (e) {
                        return e.get('display');
                    }).join(', '));

                    return selectedEmplymentAnalysisCategory.map(function (item) {
                        return item.get('display');
                    });
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsSocnetOther: Ember.computed('socnetOther', {
            get: function get(key) {
                var socnetOther = this.get('socnetOther');
                if (socnetOther) {
                    return socnetOther.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                if (value) {
                    var noneOption = value.socnetOtherOptions.findBy('shortName', 'none_above_apply');

                    if (noneOption.get('checked')) {
                        this.set('socnetOther', noneOption.get('display'));

                        value.socnetOtherOptions.rejectBy('shortName', 'none_above_apply').forEach(function (st) {
                            st.set('checked', null);
                        });

                        return [noneOption.get('display')];
                    } else {
                        var selectedSocnetOtherOptions = value.socnetOtherOptions.filterBy('checked', true);

                        if (selectedSocnetOtherOptions) {
                            this.set('socnetOther', selectedSocnetOtherOptions.map(function (e) {
                                return e.get('display');
                            }).join(', '));

                            return selectedSocnetOtherOptions.map(function (item) {
                                return item.get('display');
                            });
                        } else {
                            return false;
                        }
                    }
                }

                return false;
            }
        }),

        subjectAnalyticsSocnetThemes: Ember.computed('socnetThemes', {
            get: function get(key) {
                var socnetThemes = this.get('socnetThemes');
                if (socnetThemes) {
                    return socnetThemes.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                if (value) {
                    var noneOption = value.socnetThemesOptions.findBy('shortName', 'no_socnet_themes');

                    if (noneOption.get('checked')) {
                        this.set('socnetThemes', noneOption.get('display'));

                        value.socnetThemesOptions.rejectBy('shortName', 'no_socnet_themes').forEach(function (st) {
                            st.set('checked', null);
                        });

                        return [noneOption.get('display')];
                    } else {
                        var selectedSocnetThemesOptions = value.socnetThemesOptions.filterBy('checked', true);

                        if (selectedSocnetThemesOptions) {
                            this.set('socnetThemes', selectedSocnetThemesOptions.map(function (e) {
                                return e.get('display');
                            }).join(', '));

                            return selectedSocnetThemesOptions.map(function (item) {
                                return item.get('display');
                            });
                        } else {
                            return false;
                        }
                    }
                }
            }
        }),

        subjectAnalyticsSocnetThemesHidden: Ember.computed('socnetThemesHidden', {
            get: function get(key) {
                var socnetThemesHidden = this.get('socnetThemesHidden');
                if (socnetThemesHidden) {
                    return socnetThemesHidden.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                if (value) {
                    var noneOption = value.socnetThemesOptions.findBy('shortName', 'no_socnet_themes');

                    if (noneOption.get('checked')) {
                        this.set('socnetThemesHidden', '');

                        value.socnetThemesHiddenOptions.forEach(function (st) {
                            st.set('checked', null);
                        });

                        return [];
                    } else {
                        var selectedSocnetThemesOptions = value.socnetThemesHiddenOptions.filterBy('checked', true);

                        if (selectedSocnetThemesOptions) {
                            this.set('socnetThemesHidden', selectedSocnetThemesOptions.map(function (e) {
                                return e.get('display');
                            }).join(', '));

                            return selectedSocnetThemesOptions.map(function (item) {
                                return item.get('display');
                            });
                        } else {
                            return false;
                        }
                    }
                }
            }
        }),

        subjectAnalyticsSocnetTones: Ember.computed('socnetTones', {
            get: function get(key) {
                var socnetTones = this.get('socnetTones');
                if (socnetTones) {
                    return socnetTones.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                if (value) {
                    var noneOption = value.socnetTonesOptions.findBy('shortName', 'no_socnet_tones');

                    if (noneOption.get('checked')) {
                        this.set('socnetTones', noneOption.get('display'));

                        value.socnetTonesOptions.rejectBy('shortName', 'no_socnet_tones').forEach(function (st) {
                            st.set('checked', null);
                        });
                        return [noneOption.get('display')];
                    } else {
                        var selectedSocnetTonesOptions = value.socnetTonesOptions.filterBy('checked', true);

                        if (selectedSocnetTonesOptions) {
                            this.set('socnetTones', selectedSocnetTonesOptions.map(function (e) {
                                return e.get('display');
                            }).join(', '));

                            return selectedSocnetTonesOptions.map(function (item) {
                                return item.get('display');
                            });
                        } else {
                            return false;
                        }
                    }
                }
            }
        }),

        subjectAnalyticsPossibleConnectionsCategories: Ember.computed('possibleConnectionsCategory', {
            get: function get(key) {
                var possibleConnectionsCategory = this.get('possibleConnectionsCategory');
                if (possibleConnectionsCategory) {
                    return possibleConnectionsCategory.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var noneOption = value.possibleConnectionsCategoryOptions.findBy('shortName', 'no_possible_connections');

                if (noneOption.get('checked')) {
                    this.set('possibleConnectionsCategory', noneOption.get('display'));

                    value.possibleConnectionsCategoryOptions.rejectBy('shortName', 'no_possible_connections').forEach(function (st) {
                        st.set('checked', null);
                    });

                    return [noneOption.get('display')];
                } else {
                    var selectedPossibleConnectionsCategoryOptions = value.possibleConnectionsCategoryOptions.filterBy('checked', true);
                    if (selectedPossibleConnectionsCategoryOptions) {
                        this.set('possibleConnectionsCategory', selectedPossibleConnectionsCategoryOptions.map(function (p) {
                            return p.get('display');
                        }).join(', '));

                        return selectedPossibleConnectionsCategoryOptions.map(function (item) {
                            return item.get('display');
                        });
                    } else {
                        return false;
                    }
                }
            }
        }),

        subjectAnalyticsYearsMarried: Ember.computed('maritalStatus', {
            get: function get(key) {},
            set: function set(key, value) {
                var maritalStatus = this.get('maritalStatus');
                if (maritalStatus !== 'Married') {
                    this.set('yearsMarried', null);
                }
            }
        }),

        subjectAnalyticsHasChildren: Ember.computed('children', 'hasChildren', {
            get: function get(key) {},
            set: function set(key, value) {
                var children = this.get('children');
                if (children !== 'Has children') {
                    this.set('hasChildren', null);
                }
            }
        }),

        subjectAnalyticsEmploymentType: Ember.computed('employmentType', {
            get: function get(key) {
                var employmentType = this.get('employmentType');
                if (employmentType) {
                    return employmentType.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedEmploymentOptions = value.employmentDetailsOptions.filterBy('checked', true);

                if (selectedEmploymentOptions) {
                    this.set('employmentType', selectedEmploymentOptions.map(function (e) {
                        return e.get('display');
                    }).join(', '));

                    return selectedEmploymentOptions.map(function (item) {
                        return item.get('display');
                    });
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsEmploymentRole: Ember.computed('employmentRole', {
            get: function get(key) {
                var employmentRole = this.get('employmentRole');
                if (employmentRole) {
                    return employmentRole.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedEmploymentOptions = value.employmentDetailsOptions.filterBy('checked', true);

                if (selectedEmploymentOptions) {
                    this.set('employmentRole', selectedEmploymentOptions.map(function (e) {
                        return e.get('role');
                    }).join(', '));

                    return selectedEmploymentOptions.map(function (item) {
                        return item.get('role');
                    });
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsEmploymentOther: Ember.computed('employmentOther', {
            get: function get(key) {
                var employmentOther = this.get('employmentOther');
                if (employmentOther) {
                    return employmentOther.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedEmploymentOptions = value.employmentDetailsOptions.filterBy('checked', true);

                if (selectedEmploymentOptions) {
                    this.set('employmentOther', selectedEmploymentOptions.map(function (e) {
                        return e.get('other');
                    }).join(', '));

                    return selectedEmploymentOptions.map(function (item) {
                        return item.get('other');
                    });
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsGovernmentSectorBranches: Ember.computed('governmentSectorBranches', {
            get: function get(key) {
                var governmentSectorBranches = this.get('governmentSectorBranches');
                if (governmentSectorBranches) {
                    return governmentSectorBranches.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedBranches = value.filterBy('checked', true);

                if (selectedBranches) {
                    this.set('governmentSectorBranches', selectedBranches.map(function (e) {
                        return e.get('display');
                    }).join(', '));

                    return selectedBranches.map(function (item) {
                        return item.get('display');
                    });
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsMilitaryVeteranBranches: Ember.computed('governmentSectorBranches', {
            get: function get(key) {
                var militaryVeteranBranches = this.get('militaryVeteranBranches');
                if (militaryVeteranBranches) {
                    return militaryVeteranBranches.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedBranches = value.filterBy('checked', true);

                if (selectedBranches) {
                    this.set('militaryVeteranBranches', selectedBranches.map(function (e) {
                        return e.get('display');
                    }).join(', '));

                    return selectedBranches.map(function (item) {
                        return item.get('display');
                    });
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsMaritalAttributes: Ember.computed('maritalAttributes', {
            get: function get(key) {
                var maritalAttributes = this.get('maritalAttributes');
                if (maritalAttributes) {
                    return maritalAttributes.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedOptions = value.filterBy('checked', true);

                if (selectedOptions) {
                    this.set('maritalAttributes', selectedOptions.map(function (e) {
                        return e.get('display');
                    }).join(', '));

                    return selectedOptions.map(function (item) {
                        return item.get('display');
                    });
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsProductUse: Ember.computed('productUse', {
            get: function get(key) {
                var productUse = this.get('productUse');
                if (productUse) {
                    return productUse.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedProductUseOptions = value.productUseOptions.filterBy('checked', true);

                if (selectedProductUseOptions) {
                    this.set('productUse', selectedProductUseOptions.map(function (p) {
                        return p.get('display');
                    }).join(', '));

                    return selectedProductUseOptions.map(function (item) {
                        return item.get('display');
                    });
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsProductUseSecondaries: Ember.computed('productUseSecondaries', {
            get: function get(key) {
                var productUseSecondaries = this.get('productUseSecondaries');
                if (productUseSecondaries) {
                    return productUseSecondaries.split(', ').map(function (item) {
                        return item.trim();
                    });
                }
            }
        }),

        subjectAnalyticsJurorMedicalIssues: Ember.computed('jurorMedicalIssues', {
            get: function get(key) {
                var jurorMedicalIssues = this.get('jurorMedicalIssues');
                if (jurorMedicalIssues) {
                    return jurorMedicalIssues.split(', ').map(function (item) {
                        return item.trim();
                    });
                } else {
                    return null;
                }
            },
            set: function set(key, value) {
                var selectedMedicalOptions = value.medicalOptions.filterBy('checked', true);

                if (selectedMedicalOptions) {
                    this.set('jurorMedicalIssues', selectedMedicalOptions.map(function (m) {
                        return m.get('display');
                    }).join(', '));

                    return selectedMedicalOptions.map(function (item) {
                        return item.get('display');
                    });
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsMediaOutlet: Ember.computed('mediaOutlet', {
            get: function get(key) {
                var mediaOutlet = this.get('mediaOutlet');
                if (mediaOutlet) {
                    return mediaOutlet.split(', ').map(function (item) {
                        return item.trim();
                    });
                }
            },
            set: function set(key, value) {
                var selectedMediaOutletOptions = value.mediaOutletOptions.filterBy('checked', true);

                if (selectedMediaOutletOptions) {
                    this.set('mediaOutlet', selectedMediaOutletOptions.map(function (h) {
                        return h.get('display');
                    }).join(', '));

                    return selectedMediaOutletOptions.map(function (item) {
                        return item.get('display');
                    });
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsHigherEducation: Ember.computed('higherEducation', {
            get: function get(key) {
                var higherEducation = this.get('higherEducation');
                if (higherEducation) {
                    return higherEducation.split(', ').map(function (item) {
                        return item.trim();
                    });
                }
            },
            set: function set(key, value) {
                var selectedHigherEducationOptions = value.higherEducationOptions.filterBy('checked', true);

                if (selectedHigherEducationOptions) {
                    this.set('higherEducation', selectedHigherEducationOptions.map(function (h) {
                        return h.get('display');
                    }).join(', '));

                    var heArr = selectedHigherEducationOptions.map(function (item) {
                        return item.get('display');
                    });

                    if (!heArr.includes('Masters')) {
                        this.set('higherEducationMasters', null);
                    }

                    if (!heArr.includes('Post - Grad')) {
                        this.set('higherEducationPostGrad', null);
                    }

                    return heArr;
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsReligiousAffiliation: Ember.computed('religiousAffiliation', {
            get: function get(key) {
                var religiousAffiliation = this.get('religiousAffiliation');
                if (religiousAffiliation) {
                    return religiousAffiliation.split(', ').map(function (item) {
                        return item.trim();
                    });
                }
            },
            set: function set(key, value) {
                var selectedReligiousAffiliationOptions = value.religiousAffiliationOptions.filterBy('checked', true);

                if (selectedReligiousAffiliationOptions) {
                    this.set('religiousAffiliation', selectedReligiousAffiliationOptions.map(function (h) {
                        return h.get('display');
                    }).join(', '));

                    return selectedReligiousAffiliationOptions.map(function (item) {
                        return item.get('display');
                    });
                } else {
                    return false;
                }
            }
        }),

        subjectAnalyticsReligiousLevelOfInterest: Ember.computed('religiousLevelOfInterest', {
            get: function get(key) {
                var religiousLevelOfInterest = this.get('religiousLevelOfInterest');
                if (religiousLevelOfInterest) {
                    return religiousLevelOfInterest.split(', ').map(function (item) {
                        return item.trim();
                    });
                }
            },
            set: function set(key, value) {
                var selectedReligiousLevelOfInterestOptions = value.religiousLevelOfInterestOptions.filterBy('checked', true);

                if (selectedReligiousLevelOfInterestOptions) {
                    this.set('religiousLevelOfInterest', selectedReligiousLevelOfInterestOptions.map(function (h) {
                        return h.get('display');
                    }).join(', '));

                    return selectedReligiousLevelOfInterestOptions.map(function (item) {
                        return item.get('display');
                    });
                } else {
                    return false;
                }
            }
        }),

        // workflow related fields
        adminMainA: _emberData.default.belongsTo('employee', { async: false }),
        adminMainC: _emberData.default.attr('string'),
        adminMainS: _emberData.default.attr('string'),
        adminMainHours: _emberData.default.attr('number'),
        adminMainDeadline: _emberData.default.attr('string'),
        adminRecordsA: _emberData.default.belongsTo('employee', { async: false }),
        adminRecordsC: _emberData.default.attr('string'),
        adminRecordsS: _emberData.default.attr('string'),
        adminRecordsHours: _emberData.default.attr('number'),
        adminRecordsDeadline: _emberData.default.attr('string'),
        adminReportA: _emberData.default.belongsTo('employee', { async: false }),
        adminReportC: _emberData.default.attr('string'),
        adminReportS: _emberData.default.attr('string'),
        adminReportHours: _emberData.default.attr('number'),
        adminReportDeadline: _emberData.default.attr('string'),
        analyticsPhase1A: _emberData.default.belongsTo('employee', { async: false }),
        analyticsPhase1C: _emberData.default.attr('string'),
        analyticsPhase1S: _emberData.default.attr('string'),
        analyticsPhase1Hours: _emberData.default.attr('number'),
        analyticsPhase1Deadline: _emberData.default.attr('string'),
        analyticsPhase2A: _emberData.default.belongsTo('employee', { async: false }),
        analyticsPhase2C: _emberData.default.attr('string'),
        analyticsPhase2S: _emberData.default.attr('string'),
        analyticsPhase2Hours: _emberData.default.attr('number'),
        analyticsPhase2Deadline: _emberData.default.attr('string'),
        caseMgrA: _emberData.default.belongsTo('employee', { async: false }),
        caseMgrC: _emberData.default.attr('string'),
        caseMgrS: _emberData.default.attr('string'),
        caseMgrHours: _emberData.default.attr('number'),
        caseMgrDeadline: _emberData.default.attr('string'),
        dbInitialA: _emberData.default.belongsTo('employee', { async: false }),
        dbInitialC: _emberData.default.attr('string'),
        dbInitialS: _emberData.default.attr('string'),
        dbInitialHours: _emberData.default.attr('number'),
        dbInitialDeadline: _emberData.default.attr('string'),
        dbMainA: _emberData.default.belongsTo('employee', { async: false }),
        dbMainC: _emberData.default.attr('string'),
        dbMainS: _emberData.default.attr('string'),
        dbMainHours: _emberData.default.attr('number'),
        dbMainDeadline: _emberData.default.attr('string'),
        dbMonitorA: _emberData.default.belongsTo('employee', { async: false }),
        dbMonitorC: _emberData.default.attr('string'),
        dbMonitorS: _emberData.default.attr('string'),
        dbMonitorHours: _emberData.default.attr('number'),
        dbMonitorDeadline: _emberData.default.attr('string'),
        dbSuppA: _emberData.default.belongsTo('employee', { async: false }),
        dbSuppC: _emberData.default.attr('string'),
        dbSuppS: _emberData.default.attr('string'),
        dbSuppHours: _emberData.default.attr('number'),
        dbSuppDeadline: _emberData.default.attr('string'),
        dbWitsA: _emberData.default.belongsTo('employee', { async: false }),
        dbWitsC: _emberData.default.attr('string'),
        dbWitsS: _emberData.default.attr('string'),
        dbWitsHours: _emberData.default.attr('number'),
        dbWitsDeadline: _emberData.default.attr('string'),
        fieldMainA: _emberData.default.belongsTo('employee', { async: false }),
        fieldMainC: _emberData.default.attr('string'),
        fieldMainS: _emberData.default.attr('string'),
        fieldMainHours: _emberData.default.attr('number'),
        fieldMainDeadline: _emberData.default.attr('string'),
        fieldSuppA: _emberData.default.belongsTo('employee', { async: false }),
        fieldSuppC: _emberData.default.attr('string'),
        fieldSuppS: _emberData.default.attr('string'),
        fieldSuppHours: _emberData.default.attr('number'),
        fieldSuppDeadline: _emberData.default.attr('string'),
        officeBkA: _emberData.default.belongsTo('employee', { async: false }),
        officeBkC: _emberData.default.attr('string'),
        officeBkS: _emberData.default.attr('string'),
        officeBkHours: _emberData.default.attr('number'),
        officeBkDeadline: _emberData.default.attr('string'),
        officeCourtsA: _emberData.default.belongsTo('employee', { async: false }),
        officeCourtsC: _emberData.default.attr('string'),
        officeCourtsS: _emberData.default.attr('string'),
        officeCourtsHousr: _emberData.default.attr('number'),
        officeCourtsDeadline: _emberData.default.attr('string'),
        officeDlA: _emberData.default.belongsTo('employee', { async: false }),
        officeDlC: _emberData.default.attr('string'),
        officeDlS: _emberData.default.attr('string'),
        officeDlHours: _emberData.default.attr('number'),
        officeDlDeadline: _emberData.default.attr('string'),
        officeLeA: _emberData.default.belongsTo('employee', { async: false }),
        officeLeC: _emberData.default.attr('string'),
        officeLeS: _emberData.default.attr('string'),
        officeLeHours: _emberData.default.attr('number'),
        officeLeDeadline: _emberData.default.attr('string'),
        officeMainA: _emberData.default.belongsTo('employee', { async: false }),
        officeMainC: _emberData.default.attr('string'),
        officeMainS: _emberData.default.attr('string'),
        officeMainHours: _emberData.default.attr('number'),
        officeMainDeadline: _emberData.default.attr('string'),
        officeSocnetA: _emberData.default.belongsTo('employee', { async: false }),
        officeSocnetC: _emberData.default.attr('string'),
        officeSocnetS: _emberData.default.attr('string'),
        officeSocnetHours: _emberData.default.attr('number'),
        officeSocnetDeadline: _emberData.default.attr('string'),
        officeWA: _emberData.default.belongsTo('employee', { async: false }),
        officeWC: _emberData.default.attr('string'),
        officeWS: _emberData.default.attr('string'),
        officeWHours: _emberData.default.attr('number'),
        officeWDeadline: _emberData.default.attr('string'),
        prePublishA: _emberData.default.belongsTo('employee', { async: false }),
        prePublishC: _emberData.default.attr('string'),
        prePublishS: _emberData.default.attr('string'),
        prePublishHours: _emberData.default.attr('number'),
        prePublishDeadline: _emberData.default.attr('string'),
        qaInitialA: _emberData.default.belongsTo('employee', { async: false }),
        qaInitialC: _emberData.default.attr('string'),
        qaInitialS: _emberData.default.attr('string'),
        qaInitialHours: _emberData.default.attr('number'),
        qaInitialDeadline: _emberData.default.attr('string'),
        qaMainA: _emberData.default.belongsTo('employee', { async: false }),
        qaMainC: _emberData.default.attr('string'),
        qaMainS: _emberData.default.attr('string'),
        qaMainHours: _emberData.default.attr('number'),
        qaMainDeadline: _emberData.default.attr('string'),
        qaSuppA: _emberData.default.belongsTo('employee', { async: false }),
        qaSuppC: _emberData.default.attr('string'),
        qaSuppS: _emberData.default.attr('string'),
        qaSuppHours: _emberData.default.attr('number'),
        qaSuppDeadline: _emberData.default.attr('string'),
        rptMainA: _emberData.default.belongsTo('employee', { async: false }),
        rptMainC: _emberData.default.attr('string'),
        rptMainS: _emberData.default.attr('string'),
        rptMainHours: _emberData.default.attr('number'),
        rptMainDeadline: _emberData.default.attr('string'),
        rptSuppA: _emberData.default.belongsTo('employee', { async: false }),
        rptSuppC: _emberData.default.attr('string'),
        rptSuppS: _emberData.default.attr('string'),
        rptSuppHours: _emberData.default.attr('number'),
        rptSuppDeadline: _emberData.default.attr('string'),

        serializeWithConflictResolution: function serializeWithConflictResolution(options) {
            var snapshot = this._internalModel.createSnapshot();
            var serializer = this.store.serializerFor('subject');

            var data = {};
            var type = snapshot.type;
            serializer.serializeIntoHash(data, type, snapshot, options);
            return data['subject'];
        }
    });
});