define('tm-common/components/user-avatar', ['exports', 'tm-common/templates/components/user-avatar', 'tm-common/utils/get-app-url'], function (exports, _userAvatar, _getAppUrl) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        notify: Ember.inject.service(),
        ajax: Ember.inject.service(),

        compAdditionClass: '',
        classNameBindings: ['compAdditionClass'],

        ENV: Ember.computed(function () {
            var applicationConfig = Ember.getOwner(this).resolveRegistration('config:environment');
            return applicationConfig;
        }),

        didReceiveAttrs: function didReceiveAttrs() {
            this._super.apply(this, arguments);
            this.setAvatarUrl();
        },


        layout: _userAvatar.default,

        /**
         * @property
         *
         * set the files.id number to show the avatar
         */
        fileId: null,

        /**
         * @computed
         *
         * check if fileId exists
         */
        hasAvatar: Ember.computed.notEmpty('fileId'),

        /**
         * size of the avatar
         */
        width: 100,
        height: 100,

        /**
         * if we want the avatar rounded
         */
        circle: false,

        setAvatarUrl: function setAvatarUrl() {
            var _this = this;

            this.set('avatarUrl', null);
            if (Ember.isEmpty(this.get('fileId'))) {
                return;
            }
            var ENV = this.get('ENV');
            this.get('ajax').post('/users/generate-nonce', {
                contentType: 'application/json'
            }).then(function (response) {
                var previewUrl = (0, _getAppUrl.default)(ENV, '/files/' + _this.get('fileId') + '/preview/1?token=' + response.token);
                _this.set('avatarUrl', previewUrl);
            }).catch(function (error) {
                console.error('Error generating download nonce:', error);

                // Handle error - display message to user, etc.
                _this.get('notify').error('Error initiating download. Please try again.');
            });
        },


        /**
         * @computed
         *
         * return the url needed to preview the avatar
         */
        avatarUrlObserver: Ember.observer('fileId', function () {
            this.setAvatarUrl();
        }),

        classStr: Ember.computed('circle', 'pullLeft', 'pullRight', function () {
            var circle = this.get('circle');
            var pullLeft = this.get('pullLeft');
            var pullRight = this.get('pullRight');

            var classNameArr = [];
            if (circle) {
                classNameArr.push('img-circle');
            }
            if (pullLeft) {
                classNameArr.push('pull-left');
            }
            if (pullRight) {
                classNameArr.push('pull-right');
            }
            classNameArr.push(this.get('additionalClass'));

            return classNameArr.join(' ');
        })
    });
});